var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"marketingCenter"},[_c('li',{staticClass:"vt item"},[_c('div',{staticClass:"nav",on:{"click":function($event){return _vm.detail('/tool/naire')}}},[_vm._m(0),_vm._m(1)])]),_c('li',{staticClass:"vt item"},[_c('div',{staticClass:"nav",on:{"click":function($event){return _vm.detail('/Redeem/redeemActionList')}}},[_vm._m(2),_vm._m(3)])]),_c('li',{staticClass:"vt item"},[_c('div',{staticClass:"nav",on:{"click":function($event){return _vm.detail('/marketingFission/fissionList')}}},[_vm._m(4),_vm._m(5)])]),_c('li',{staticClass:"vt item"},[_c('div',{staticClass:"nav",on:{"click":function($event){return _vm.detail('/CourseCoupon/couponList')}}},[_vm._m(6),_vm._m(7)])]),_c('li',{staticClass:"vt item"},[_c('div',{staticClass:"nav",on:{"click":function($event){return _vm.detail('/TeamCourse/TeamCourseList')}}},[_vm._m(8),_vm._m(9)])]),_vm._m(10)]),(_vm.iframeSrc)?_c('my-iframe',{attrs:{"src":_vm.iframeSrc},on:{"close":function($event){_vm.iframeSrc = ''}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/img/marketingCenter/icon_1.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("用户调查表")]),_c('div',{staticClass:"detail"},[_vm._v("收集用户手机号码、qq、地址等详细资料信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/img/marketingCenter/icon_2.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("兑换码")]),_c('div',{staticClass:"detail"},[_vm._v(" 一键生成随机码、赠送码，实现线上线下有效转化 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/img/marketingCenter/icon_3.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("裂变海报")]),_c('div',{staticClass:"detail"},[_vm._v("快速传播，分享赚佣金，提高销量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/img/marketingCenter/icon_4.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("优惠券")]),_c('div',{staticClass:"detail"},[_vm._v("运营必备，百搭又实用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/img/marketingCenter/icon_5.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("多人拼团")]),_c('div',{staticClass:"detail"},[_vm._v("引发裂变传播，为你带来源源不断的订单和生源")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"vt item"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/img/marketingCenter/icon_6.svg")}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("敬请期待")]),_c('div',{staticClass:"detail"},[_vm._v("即将开放更多营销功能")])])])])
}]

export { render, staticRenderFns }