<template>
  <div>
    <ul class="marketingCenter">
      <li class="vt item">
        <div @click="detail('/tool/naire')" class="nav">
          <div class="icon">
            <img class="img" src="../../assets/img/marketingCenter/icon_1.svg" />
          </div>
          <div class="content">
            <div class="title">用户调查表</div>
            <div class="detail">收集用户手机号码、qq、地址等详细资料信息</div>
          </div>
        </div>
      </li>
      <li class="vt item">
        <div @click="detail('/Redeem/redeemActionList')" class="nav">
          <div class="icon">
            <img class="img" src="../../assets/img/marketingCenter/icon_2.svg" />
          </div>
          <div class="content">
            <div class="title">兑换码</div>
            <div class="detail">
              一键生成随机码、赠送码，实现线上线下有效转化
            </div>
          </div>
        </div>
      </li>
      <li class="vt item">
        <div @click="detail('/marketingFission/fissionList')" class="nav">
          <div class="icon">
            <img class="img" src="../../assets/img/marketingCenter/icon_3.svg" />
          </div>
          <div class="content">
            <div class="title">裂变海报</div>
            <div class="detail">快速传播，分享赚佣金，提高销量</div>
          </div>
        </div>
      </li>
      <li class="vt item">
        <div @click="detail('/CourseCoupon/couponList')" class="nav">
          <div class="icon">
            <img class="img" src="../../assets/img/marketingCenter/icon_4.svg" />
          </div>
          <div class="content">
            <div class="title">优惠券</div>
            <div class="detail">运营必备，百搭又实用</div>
          </div>
        </div>
      </li>
      <li class="vt item">
        <div @click="detail('/TeamCourse/TeamCourseList')" class="nav">
          <div class="icon">
            <img class="img" src="../../assets/img/marketingCenter/icon_5.svg" />
          </div>
          <div class="content">
            <div class="title">多人拼团</div>
            <div class="detail">引发裂变传播，为你带来源源不断的订单和生源</div>
          </div>
        </div>
      </li>
      <li class="vt item">
        <div class="nav">
          <div class="icon">
            <img class="img" src="../../assets/img/marketingCenter/icon_6.svg" />
          </div>
          <div class="content">
            <div class="title">敬请期待</div>
            <div class="detail">即将开放更多营销功能</div>
          </div>
        </div>
      </li>
    </ul>
    <!--内嵌网页-->
    <my-iframe v-if="iframeSrc" :src="iframeSrc" @close="iframeSrc = ''"></my-iframe>
  </div>
</template>
<script>
export default {
  name: 'MarketingCenter',

  data() {
    return {
      iframeSrc: ''
    }
  },

  computed: {
    showUpdatePopup() {
      return this.$store.getters.isVersionFree || this.$store.getters.isVersionStandard
    }
  },

  methods: {
    detail(url) {
      if (this.showUpdatePopup) {
        this.$root.toggleUpdatePopup()
      } else {
        this.iframeSrc = url
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.marketingCenter {
  font-size: 0;
  padding: 0 10px;
  margin-top: 28px;
  .item {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    .nav {
      cursor: pointer;
      display: flex;
      padding: 14px;
      border-radius: 2px;
      align-items: center;
      background: rgba(245, 245, 245, 1);
      &:hover {
        background-color: #eee;
      }
      .icon {
        width: 58px;
        .img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .content {
        flex: 1;
        margin-left: 19px;
        .title {
          font-size: 17px;
          font-weight: 400;
          line-height: 24px;
          color: rgba(54, 54, 54, 1);
        }
        .detail {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: rgba(153, 153, 153, 1);
        }
      }
    }
  }
}
</style>
